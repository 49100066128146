/* Prodotto.css */
.prodotto-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-image: url(/src/assets/sfondo-prodotto.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;

}


.canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@media (max-width: 768px) {
  .canvas {
    width: 70%;
    height: 70%;
  }
  

}

@media (max-width: 480px) {
  .canvas {
    width: 90%;
    height: 90%;
  }
  .prodotto-container{
    height: 50vh;
  }
}

