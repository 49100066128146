/* Header.css */

.header {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; /* Centering content */
  padding: 20px; /* Add padding for smaller screens */
  z-index: 2; /* Ensure header is above noise overlay */
  
}
.btnh{

  display: inline-block;
  background-color: #ff5c35;
  border: none;
  color: #ff5c35;
  padding: 15px 50px;
  font-size: 1.25rem;
  font-weight: 800;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.15s cubic-bezier(0.25, 1, 0.5, 1);

}



.header .container {
  position: relative;
  height: 100%;
  z-index: 3;
  max-width: 1200px; /* Limit the max width for larger screens */
  width: 100%;
  padding: 0 15px; /* Add padding for responsiveness */
}

.header img.img-fluid {
  width: 100%;
  max-width: 500px;
  height: auto; /* Ensure the image is responsive */
  margin-top: 60px; /* Add margin-top for the image in mobile view */
}

.header h1 {
  color: #fff;
  font-size: 3.5rem;
  margin-bottom: 0.5rem;
  font-weight: 800;
  text-align: left; /* Align text to the left */
  white-space: nowrap; /* Prevent the text from wrapping */
}

.header h1 .highlight {
  color: #ff5c35;
  font-weight: 800;
}

.header h1 .trademark {
  font-size: 2rem;
  margin-left: 0.2rem;
  position: relative;
  top: -0.9rem;
}

.header h2 {
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 800;
  text-align: left; /* Align text to the left */
}

.header h3 {
  color: #ff5c35;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  text-align: left; /* Align text to the left */
}

.ellisse {
  position: absolute;
  top: 70%;
  right: 5%;
  width: 200px;
  height: 100px;
  opacity: 0; /* Start hidden */
}

.ellisse svg ellipse {
  fill: transparent;
  stroke: #ff5c35;
  stroke-width: 2px;
  animation: stroke-animation 2s infinite;
}

.ellisse svg text {
  fill: #ff5c35;
  font-size: 16px;
  font-weight: bold;
  animation: slide-in-out 4s infinite;
}

@keyframes stroke-animation {
  0% {
    stroke-dasharray: 0 100%;
  }
  50% {
    stroke-dasharray: 100% 0;
  }
  100% {
    stroke-dasharray: 0 100%;
  }
}

@keyframes slide-in-out {
  0%, 100% {
    opacity: 0;
    transform: translateY(10px);
  }
  25%, 75% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles */
@media (max-width: 900px) {
  .header {
    height: auto; /* Adjust height for smaller screens */
    padding: 40px 0;
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the left */
    
  }

  .header img.img-fluid {
    margin-top: 20px; /* Reduce margin-top for smaller screens */
    margin-bottom: 20px; /* Add margin-bottom to separate from texts */
  }

  .header .container {
    text-align: left; /* Align text and button to the left */
    margin-bottom: 200px;
  }

  .header h1 {
    font-size: 2.3rem; /* Adjust font size for smaller screens */
    white-space: nowrap; /* Prevent the text from wrapping */
  }

  .header h2 {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }

  .header h3 {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
    margin-bottom: 10px;
  }

  .header .btn {
    margin-bottom: 20px; /* Add margin below the button */
    display: block;
    text-align: center;
    width: 100%;
    
  }

  .header h1 .highlight {
    color: #ff5c35;
    font-weight: 800;
    display: inline;
  }
  
  .ellisse {
    top: 110%;
    right: 25%;
    width: 200px;
    height: 75px;
  }
}
