/* src/styles/Slider.css */
.slider-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.slider-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #ff5c35; /* Colore arancione per il titolo */
 /* Maiuscolo */
  font-weight: 500; /* Peso del font 800 */
}

.slider {
  position: relative;
  width: 80%;
  overflow: hidden;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  min-width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.dots {
  text-align: center;
  margin: 20px 0;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #ff5c35;
}

.button-container {
  text-align: center;
  margin-top: 40px;
}

.image-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.image-modal.hide {
  animation: fadeOut 1s forwards;
}

.modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 60%;
  max-height: 60%;
  animation: slideIn 0.5s forwards;
}

.nutritional-image {
  max-width: 100%;
  max-height: 100%;
}

.close-button {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 24px;
  color: #ff5c35;
  cursor: pointer;
  transition: color 0.3s;
  z-index: 1001;
}

.close-button:hover {
  color: #ff5c35;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50%);
  }
}
