/* src/components/SectionIngredienti.css */
.section-ingredienti {
  position: relative;
  font-family: 'Termina', sans-serif;
  z-index: 2; /* Ensure section ingredienti is above noise overlay */
  padding: 20px;
}

.ingredienti-title {
  font-size: 2em;
  margin-bottom: 20px;
}

.ingredienti-shape-container {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}

.ingredienti-shape {
  width: 100px;
  height: auto;
}

.shape-text {
  position: absolute; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8em;
  text-align: center;
}

.ingredienti-reference{
  color: #ff5c35;
  font-size: 0.8rem;
}

.ingredienti-main-title {
  font-size: 1.5em;
  font-weight: bold;
}

.highlight {
  color: #ff6600;
}

.ingredienti-description {
  margin-top: 10px;
  font-size: 1em;
  text-align: justify; /* Justifica il testo */
}


.section-i-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #ff5c35; /* Colore arancione per il titolo */
 /* Maiuscolo */
  font-weight: 700; /* Peso del font 800 */
  text-align: center;
  margin-bottom: 50px;
}

.glass-animation-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.glass-animation {
  width: 100px; /* Dimensione dell'animazione */
  height: auto;
}



/* Responsive Styles */
@media (max-width: 768px) {
  .section-ingredienti {
    padding: 40px 20px; /* Add padding for smaller screens */
  }

  .ingredienti-shape-container,
  .ingredienti-main-title,
  .highlight,
  .ingredienti-description,
  .ingredienti-icon,
  .ingredienti-subtitle,
  .ingredienti-text {
    margin-bottom: 10px;
  }

  .ingredienti-reference{
    text-align: center;
  }
  .ingredienti-shape-container {
    margin-bottom: 20px; /* Add margin bottom to the orange shape */
  }

  .ingredienti-shape-container,
  .ingredienti-main-title,
  .highlight,
  .ingredienti-description {
    display: block;
    text-align: center;
  }

  .ingredienti-icon,
  .ingredienti-subtitle,
  .ingredienti-text {
    display: block;
    text-align: center;
  }

  .ingredienti-text{
    text-align: justify;
    text-justify: inter-word;
    hyphens: auto; /* Aggiungi questa linea per migliorare la giustificazione */
    line-height: 1.5; /* Regola l'interlinea per una migliore leggibilità */
  }

}
