/* NavBar.css */
.navbar {
  background: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3; /* Navbar above noise overlay */
  padding: 1rem 2rem; /* Adjust padding for better alignment */
  transition: background-color 0.3s, padding 0.3s;
  display: flex;
  align-items: center;
}

.navbar.opaque {
  background: rgba(8, 55, 140, 0.9);
}

.navbar.shrink {
  padding: 0.5rem 1rem;
}

.navbar-brand {
  margin-right: 2rem;
}

.nav-link {
  color: #ff5c35 !important;
  margin-right: 1rem;
  transition: font-size 0.3s, padding 0.3s;
  font-weight: 700;
}

.nav-link.shrink {
  font-size: 0.9rem;
}

.logo {
  height: 22px; /* Slightly larger than text */
  transition: height 0.3s;
}

.logo.shrink {
  height: 25px; /* Shrink logo slightly */
}

.navbar-toggler {
  border: none;
  background: transparent !important;
  outline: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

/* Media Query for responsive design */
@media (max-width: 768px) {
  .navbar {
    padding: 0.5rem 1rem;
  }

  .navbar-brand {
    margin-right: 0;
  }

  .nav-link {
    margin-right: 0;
    font-size: 1rem;
  }

  .nav-link.shrink {
    font-size: 0.8rem;
  }

  .navbar-collapse {
    background: rgba(8, 55, 140, 0.9);
    padding: 1rem;
  }

  .nav-link {
    margin: 0.5rem 0;
    text-align: left;
  }
}