/* App.css */

body, html {
  margin: 0;
  padding: 0;
  font-family: "termina", sans-serif;
  background-color: #08378c;
  overflow-x: hidden;
  position: relative; /* Ensure body and html are the lowest layers */
  z-index: 0;
}

.noise-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: url('./assets/bg-mask.static.png') repeat;
  opacity: 0.2;
  z-index: 1; /* Ensure it is above the background color but below the section content */
}

.img-fluid,
.section-image {
  animation: float 5s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.section {
  
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  position: relative;
  z-index: 2; /* Ensure sections are above noise overlay */
  padding: 20px;
}

.section h1 {
  font-size: 3rem;
  transition: transform 0.3s, color 0.3s;
}

.section h1:hover {
  transform: scale(1.1);
  color: #FFF;
}

/* Button Styles */
.btn {
  display: inline-block;
  background-color: #ff5c35;
  border: none;
  color: #ff5c35;
  padding: 15px 50px;
  font-size: 1.25rem;
  font-weight: 800;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.15s cubic-bezier(0.25, 1, 0.5, 1);
}

.btn::before {
  content: '';
  background-color: #fff;
  position: absolute;
  top: 6px;
  left: -6px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  z-index: -1;
  transition: all 0.15s cubic-bezier(0.25, 1, 0.5, 1);
}

.btn:hover::before {
  top: 0;
  left: 0;
}

.btn:hover {
  transform: translate(6px, -6px);
  color: #ff5c35;
}

.btn:active {
  background-color: #ff5c35;
  transform: translate(0);
}

.btn span {
  font-family: 'Termina Bold', sans-serif !important;
}

#slider {
  margin-top: 150px;
}

/* Media Queries for responsive design */
@media (max-width: 1200px) {
  .container {
    max-width: 960px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 720px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 540px;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 100%;
    padding: 0 15px;
  }

  .section {
    height: auto; /* Adjust height for smaller screens */
    padding: 40px 20px;
  }

  .noise-overlay {
    z-index: -1; /* Move the noise overlay behind the content on small screens */
  }
}
