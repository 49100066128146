/* src/components/SectionContent.css */
.section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 1200px; /* Larger max width for larger screens */
  margin: 0 auto;
  padding: 20px;
  z-index: 2; /* Ensure section content is above noise overlay */
 
}


@media (max-width: 1600px) {
  .section-content {
    max-width: 1000px; /* Medium screens */
  }
}

@media (max-width: 1200px) {
  .section-content {
    max-width: 800px; /* Smaller screens */
  }
}

@media (max-width: 768px) {
  .section-content {
    max-width: 90%; /* Mobile screens */
  }
}

.section-image {
  width: 80%; /* Adjust size as needed */
  height: auto;
  margin-bottom: 20px;
  animation: float 5s ease-in-out infinite; /* Add floating animation */
}

.section-paragraph {
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: justify; /* Justify the text */
  width: 100%;
}

.section-icons-ellipse {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center; /* Center align icons */
  margin-bottom: 20px; /* Add margin below the icons */
}

.section-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.icon {
  width: 80px; /* Adjust size as needed */
  height: 80px;
  margin-bottom: 5px; /* Space between icon and text */
}

.icon-item p {
  margin-top: 5px;
  font-size: 0.9em;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .section-content {
    text-align: center;
  }

  .section-image {
    width: 130%; /* Make the image larger on mobile */
  }

  .icon{
    width: 50%;
  }


  .section-icons {
    flex-direction: row;
  }

  .icon-item {
    margin-right: 10px;
  }

  .section-paragraph {
    text-align: justify;
    text-justify: inter-word;
    hyphens: auto; /* Aggiungi questa linea per migliorare la giustificazione */
    line-height: 1.5; /* Regola l'interlinea per una migliore leggibilità */
  }


}
